<!--
 * @Author: 月魂
 * @Date: 2021-06-08 09:04:57
 * @LastEditTime: 2022-12-14 09:28:11
 * @LastEditors: 月魂
 * @Description:
 * @FilePath: \nep-concert\src\components\footer.vue
-->
<template>
  <div class="footer">
    <div class="support title">{{ this.$t('footer.tec.title') }}: {{ this.$t('footer.tec.name') }}</div>
    <a href="http://beian.miit.gov.cn/" target="_blank" class="bei title">陕ICP备20001779号-1</a>
    <div class="visit title">Visitors: {{ visitNum }}</div>
  </div>
</template>

<script>
import Vue from 'vue'
import Share from 'vue-social-share'
import 'social-share.js/dist/css/share.min.css'
import { Row, Col } from 'vant'
import axios from 'axios'

Vue.use(Row).use(Col).use(Share)

export default {
  name: 'Footer',
  data() {
    return {
      visitNum: 0,
      config: {
        url: 'http://nep-fp.topartsintermational.com/',
        title: 'QIN',
        source: 'http://nep-fp.topartsintermational.com/',
        // image: 'http://image.qinwen100.com/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20210621092946.jpg',
        sites: [ 'facebook', 'twitter' ]
      }
    }
  },
  mounted() {
    axios.get('https://silkroad8th.scpece.org.cn:18990/api/v1/2').then(res => {
      this.visitNum = res.data;
    })
    axios.put('https://silkroad8th.scpece.org.cn:18990/api/v1/2')
  }
}
</script>

<style scoped lang="less">
.footer {
    display: block;
    padding: 18px 0;
    font-size: 12px;
    font-family: SourceHanSansSC-Medium;
    text-align: center;
    // color: #052c34;
    color: #999;
    /* background: #a0d5ca; */
    background-color: rgba(100,100,100, 0.05);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    position: absolute;
    width: 100%;
    bottom: 0px;
    z-index: 1;
  .title {
    text-align: center;
    text-indent: 0em !important;
  }
  .bei {
    display: block;
    text-align: center;
    font-size: 12px;
    // color: #052C34;
    color: #999;
  }
}
</style>
