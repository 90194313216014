/*
 * @Author: 月魂
 * @Date: 2022-09-26 16:56:56
 * @LastEditTime: 2022-10-26 14:16:28
 * @LastEditors: 月魂
 * @Description: 
 * @FilePath: \nep-concert\src\router\index.js
 */
import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import zh from '../../public/json/zh.json'
import fr from '../../public/json/fr.json'
import i18n from '../i18n/i18n'

Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		name: 'Screen',
		component: () => import('../views/Screen.vue'),
	},
	{
		path: '/index',
		name: 'Home',
		component: Home,
  },
  // {
  //   path: '/detail',
  //   name: 'Detail',
  //   component: () => import('../views/Detail.vue')
  // }
];

const router = new VueRouter({
	routes,
});

router.beforeEach(async (to, from, next) => {
  if (i18n.te('title')) {
    next()
  } else {
		i18n.mergeLocaleMessage('zh', zh)
		i18n.mergeLocaleMessage('fr', fr)
    next()
  }
})

export default router;
