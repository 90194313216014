/*
 * @Author: 月魂
 * @Date: 2022-10-01 13:09:52
 * @LastEditTime: 2022-10-01 13:39:54
 * @LastEditors: 月魂
 * @Description: 
 * @FilePath: \nep-concert\src\i18n\i18n.js
 */
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import messages from './langs'

Vue.use(VueI18n)
const language = localStorage.getItem('locale')
const i18n = new VueI18n({
  locale: language || 'fr', // 此处切换语言
  messages
})

export default i18n
