<!--
 * @Author: 月魂
 * @Date: 2021-06-01 09:34:14
 * @LastEditTime: 2022-12-14 09:26:42
 * @LastEditors: 月魂
 * @Description:
 * @FilePath: \nep-concert\src\views\Home.vue
-->
<template>
  <div class="home">
    <div class="header">
      <span class="header-text">{{ this.$t("title") }}</span>
    </div>
    <div class="cont">
      <div class="part-des">
        <div class="des-cont">
          <div class="btn" @click="changeLang">{{ selected === 'fr' ? '中文' : 'FR' }}</div>
          <!-- <van-button class="btn" type="default" size="small" @click="changeLang">{{ selected === 'fr' ? '中文' : 'FR' }}</van-button> -->
          <div class="des-title">{{ this.$t('sTitle') }}</div>
          <div class="des">{{ this.$t('des1') }}</div>
          <div class="video-box">
            <video class="video" :poster="this.$t('posterUrl')" controls id="video" controlsList="nodownload" x5-playsinline playsinline>
              <source :src="this.$t('videoUrl')" >
            </video>
          </div>
          <div class="des final" v-for="(des, idx) in this.$t('des2')" :key="idx">{{ des }}</div>
        </div>
      </div>
    </div>
    <div class="footermagrin"></div>
    <Footer />
    <img src="../assets/wen.jpg" alt="" class="wen">
  </div>
</template>

<script>
import Vue from 'vue'
import Footer from '../components/footer.vue'
import { Button, Tab, Tabs, Popover, Icon } from 'vant'
Vue.use(Button).use(Tabs).use(Tab).use(Popover).use(Icon)

export default {
  name: 'Home',
  components: {
    Footer
  },
  data () {
    return {
      selected: '',
      active: 0
    }
  },
  mounted () {
    const lang = localStorage.getItem('locale')
    this.selected = lang || 'fr'
    window.oncontextmenu = function(e) {
      e.preventDefault()
    }
  },
  methods: {
    jumpToDetail() {
      this.$router.push('/detail')
    },
    handleClick () {
      const vid = document.getElementById('video')
      vid.currentTime = 10
    },
    changeLang () {
      const par = {
        zh: 'fr',
        fr: 'zh'
      }
      this.$i18n.locale = par[this.selected]
      localStorage.setItem('locale', par[this.selected])
      this.selected = par[this.selected]
    }
  }
}
</script>

<style lang="less" scoped>

video::-internal-media-controls-download-button {
    display:none;
}
video::-webkit-media-controls-enclosure {
    overflow:hidden;
}
video::-webkit-media-controls-panel {
    width: calc(100% + 30px); 
}

.footermagrin{
  padding: 40px;
}

h1,h2,h3,h4,h5,h6 {
  padding: 0;
  margin: 0;
}
.home {
  min-height: 100%;
  // background: linear-gradient(to bottom, #ECFFFB, #D8FFF7);
  background: linear-gradient(to bottom, rgba(236,255,251,0.1), rgba(216,255,247,0.1));
  position: relative;
  .wen {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
  }
  .header {
    height: 48px;
    line-height: 48px;
    font-weight: 400;
    display: flex;
    overflow: hidden;
    justify-content: center;
    // background: #e9fdfa;
    background: #fbfdfd;
    box-shadow: 1px 1px 1px 1px rgba(100, 100, 100, 0.1);
    position: sticky;
    top: 0;
    z-index: 2;
    .menu {
      display: block;
    }
    .nav {
      display: none;
    }
    @media (min-width: 800px) {
      .nav {
        display: block;
      }
      .menu {
        display: none;
      }
      & {
        position: relative;
      }
    }
    .header-text {
      display: inline-block;
      line-height: 48px;
      // color: #052C34;
      font-size: 16px;
      color: #000000;
    }
  }
  .cont {
    max-width: 1200px;
    margin: auto;
    overflow: hidden;
    z-index: 10;
  }
  .part-des {
    .des-cont {
      position: relative;
      padding-top: 16px;
      .btn {
        width: 35px;
        height: 24px;
        line-height: 24px;
        font-size: 12px;
        text-align: center;
        border: 1px solid #264E41;
        border-radius: 5px;
        position: absolute;
        top: 12px;
        right: 16px;
        z-index: 1;
      }
      .des-title {
        line-height: 28px;
        font-size: 16px;
        color: #052C34;
        text-align: center;
        margin-bottom: 8px;
      }
      .video-box {
        width: 100%;
        padding: 0 16px;
        box-sizing: border-box;
        margin-top: 16px;
        .video {
          display: block;
          width: 100%;
          border-radius: 10px;
          overflow: hidden;
          position: relative;
          z-index: 1;
        }
        @media (min-width: 800px) {
          & {
            padding: 0;
          }
        }
      }
      
    }
    @media (min-width: 1200px) {
      & {
        // width: 1200px;
        width: 100%;
        margin: auto;
      }
    }
    .des {
      padding: 0 16px;
      text-indent: 2em;
      font-size: 14px;
      line-height: 28px;
      text-align: justify;
      color: #052C34;
      margin-top: 18px;
    }
    .final:last-child {
      padding-bottom: 60px;
    }
    @media (min-width: 800px) {
      .des {
        font-size: 16px;
        padding: 5px 0;
      }
      .final:last-child {
        padding-bottom: 120px;
      }
    }
  }
}
</style>
