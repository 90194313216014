/*
 * @Author: 月魂
 * @Date: 2022-10-01 13:09:52
 * @LastEditTime: 2022-10-01 13:26:02
 * @LastEditors: 月魂
 * @Description: 
 * @FilePath: \nep-concert\src\i18n\langs\index.js
 */
import fr from './fr'
import zh from './zh'
export default {
  fr,
  zh
}
